import * as RadixDialog from '@radix-ui/react-dialog';
import { forwardRef } from 'react';

import { type ButtonAction, ButtonActions, Icon, IconBadge, Link, Markdown } from '~/components/granular';
import { classNames } from '~/utils/styles';
import type { DialogProps } from './Dialog.types';

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      actions,
      children,
      className = '',
      icon,
      iconAppearance,
      linkAction,
      markdown,
      open,
      preventOutsideClickClose,
      title,
      trigger,
      width = 'medium',
      onOpenChange,
      ...props
    },
    forwardedRef,
  ) => (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      {trigger ? <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger> : null}
      <RadixDialog.Portal>
        <Overlay />
        <RadixDialog.Content
          ref={forwardedRef}
          className={classNames(
            'fixed top-[50%] left-[50%] z-20 max-h-[85vh] w-full translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white shadow-xl focus:outline-none data-[state=open]:animate-contentShow',
            {
              'max-w-[480px]': width === 'medium',
              'max-w-[875px]': width === 'large',
              'max-w-[calc(100%-10rem)]': width === 'full',
            },
            className,
          )}
          onPointerDownOutside={(event) => preventOutsideClickClose && event.preventDefault()}
          aria-describedby={undefined}
          {...props}
        >
          <div className="flex items-center border-neutral-200 border-b border-solid px-6 py-4">
            {icon && <IconBadge icon={icon} appearance={iconAppearance} size="small" className="mr-3" />}
            <RadixDialog.Title className="title-200 text-neutral-1000">{title}</RadixDialog.Title>
            <RadixDialog.Close asChild className="ml-auto">
              <button type="button" aria-label="Close">
                <Icon icon="CloseIcon" width={24} height={24} className="text-neutral-1000" />
              </button>
            </RadixDialog.Close>
          </div>

          <div className="body-200-light px-6 py-5 text-neutral-800">
            {markdown && <Markdown markdown={markdown} />}
            {children}
          </div>

          {(actions || linkAction) && (
            <div className="flex justify-end px-6 pt-5 pb-6">
              {linkAction ? (
                <Link
                  key={linkAction.id}
                  leftIcon={linkAction.leftIcon}
                  rightIcon={linkAction.rightIcon}
                  href={linkAction.href}
                  target={linkAction.target}
                >
                  {linkAction.children}
                </Link>
              ) : (
                <ButtonActions actions={actions as Array<ButtonAction>} />
              )}
            </div>
          )}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  ),
);

const Overlay = forwardRef<HTMLDivElement>((_, forwardedRef) => {
  // This component replaces the Radix Overlay to work around issue when rendering in Nuxt application
  return (
    <div ref={forwardedRef} className="fixed inset-0 z-20 bg-black opacity-50 data-[state=open]:animate-overlayShow" />
  );
});

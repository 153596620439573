import { forwardRef } from 'react';
import { classNames } from '~/utils/styles';
import { AlertBarIcon, type AlertBarProps } from '.';

export const AlertBar = forwardRef<HTMLDivElement, AlertBarProps>(
  ({ className, children, appearance = 'info', size = 'large', icon, title, text }, forwardedRef) => {
    const classes = classNames(
      {
        'flex gap-2 rounded-lg p-4': true,
        'bg-teal-100': appearance === 'info',
        'bg-green-100': appearance === 'success',
        'bg-yellow-100': appearance === 'warning',
        'bg-red-100': appearance === 'error',
      },
      className,
    );

    const titleClasses = classNames('text-neutral-1000', {
      'body-100-heavy': size === 'large',
      'body-200-light': size === 'small',
    });

    return (
      <div ref={forwardedRef} className={classNames(classes)} role="alert">
        <AlertBarIcon className="flex-shrink-0" appearance={appearance} icon={icon} size={size} />
        <div className="flex flex-col gap-1">
          {title && (
            <div className={titleClasses} role="heading" aria-level={4}>
              {title}
            </div>
          )}
          {size === 'large' && (
            <>
              {text && <div className="body-200-light text-neutral-800">{text}</div>}
              {children}
            </>
          )}
        </div>
      </div>
    );
  },
);
